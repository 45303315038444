import React, { useState } from 'react';
import { IoCall } from "react-icons/io5";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white-800 p-4 flex shadow-md h-32 z-10">
      <div className="container mx-auto flex md:justify-between items-center">
        <div className="flex-shrink-0">
          <img src="/logo.png" alt="Logo" className="w-auto h-40" />
        </div>
        <div className="md:hidden flex justify-end w-full">
          <button onClick={toggleMenu} className="text-[#000000] focus:outline-none">
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"} />
            </svg>
          </button>
        </div>
        {/* <div className={`md:flex ${isOpen ? 'block' : 'hidden'} justify-center`}>
          <a href="#" className="block md:inline-block text-[#000000] px-3 py-2 rounded-md">Home</a>
          <a href="#" className="block md:inline-block text-[#000000] px-3 py-2 rounded-md">About</a>
          <a href="#" className="block md:inline-block text-[#000000] px-3 py-2 rounded-md">How It Works</a>
          <a href="#" className="block md:inline-block text-[#000000] px-3 py-2 rounded-md hidden">Contact</a>
        </div> */}
        <div className={`md:flex ${isOpen ? 'block' : 'hidden'} justify-center`}>
          <a href="#" className="block md:inline-block text-[#000000] px-3 py-2 rounded-md ">
            <div className="flex items-center">
              <IoCall className="mr-2" /> +254 713 099 605
            </div>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
