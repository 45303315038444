import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Hero.css';

const Hero = () => {
  const initialValues = { email: '' };
  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formData));
    setIsSubmit(true);
  };

  useEffect(() => {
    const submitData = async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        try {
          const response = await axios.post('http://127.0.0.1:8000/', { email: formData.email });
          setMessage('Successfully added to the waiting list!');
          setFormData(initialValues);
        } catch (error) {
          if (error.response) {
            setMessage('Failed to add to the waiting list.');
          } else {
            setMessage('An error occurred.');
          }
        } finally {
          setIsSubmit(false);
        }
      }
    };
    submitData();
  }, [formErrors, isSubmit]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/;

    if (!values.email) {
      errors.email = 'Email required!';
    } else if (!regex.test(values.email)) {
      errors.email = 'Please enter a valid email address';
    }

    return errors;
  };

  return (
    <div className="bg-gray-100 min-h-[calc(100vh-8rem)] flex flex-col justify-center items-center p-4 relative overflow-hidden">
      <div className="absolute inset-0 animated-bg opacity-5" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/bus.png)` }}></div>
      <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4 text-black text-center z-10">Access Public Transport With Ease</h1>
      <p className="text-base sm:text-lg md:text-xl lg:text-2xl mb-6 w-full sm:w-3/4 md:w-2/3 lg:w-2/5 text-center z-10">Our app is designed to make accessing public transport easier and more convenient. Get real-time updates, plan your routes, and pay your fare with just a few taps. Sign up now to be the first one to know when we launch!</p>
      <form className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg z-10" onSubmit={handleSubmit}>
        <div className="flex flex-col sm:flex-row items-center py-4">
          <input
            className="appearance-none bg-white border border-gray-300 rounded-full w-full text-gray-700 mr-0 sm:mr-3 py-4 px-4 leading-tight focus:outline-none focus:ring-2 focus:ring-[#F9B800]"
            type="email"
            name="email"
            placeholder="Enter your email"
            aria-label="Email"
            value={formData.email}
            onChange={handleChange}
          />
          <button
            className="flex-shrink-0 bg-[#F9B800] hover:bg-[#d18e00] text-lg text-white font-semibold py-4 px-4 w-full sm:w-auto rounded-full mt-2 sm:mt-0 shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
            type="submit"
          >
            Join Waiting List
          </button>
        </div>
        {formErrors.email && <p className="errors text-red-500 text-sm mt-2">{formErrors.email}</p>}
      </form>
      {message && <p className="mt-4 text-center text-black">{message}</p>}
    </div>
  );
};

export default Hero;
