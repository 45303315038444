import './App.css';
import Navbar from './components/NavBar';
import Hero from './components/Hero';
// import Download from './components/Download';
// import Features from './components/Features';
// import HowItWorks from './components/HowItWorks';
// import Reviews from './components/Reviews';
// import FAQ from './components/FAQ';
// import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
        <Navbar />
        <Hero />
        {/* <Download />
        <Features />
        <HowItWorks />
        <Reviews />
        <FAQ />
        <Footer /> */}
    </div>
  );
}

export default App;
